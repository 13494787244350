<template>
    <div class="container">
        <div class="container__wrap">   
            <div class="section">
                <div class="section__name">Музыка</div>

                <div v-if="!this.datas" class="msg_box">
                    <div class="msg__icn">
                        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_143_305)">
                                <path d="M53.3333 32C53.3314 39.467 55.2892 46.8038 59.011 53.2772C62.7328 59.7505 68.0883 65.1338 74.5424 68.8891C80.9964 72.6444 88.3229 74.6401 95.7898 74.6769C103.257 74.7136 110.603 72.7902 117.093 69.0987C114.528 96.16 91.7333 117.333 64 117.333C34.544 117.333 10.6667 93.456 10.6667 64C10.6667 36.2667 31.84 13.472 58.9013 10.9067C55.2403 17.333 53.321 24.604 53.3333 32ZM21.3333 64C21.3333 75.3159 25.8285 86.1683 33.8301 94.1699C41.8317 102.171 52.6841 106.667 64 106.667C71.5385 106.666 78.9426 104.67 85.4596 100.881C91.9766 97.0916 97.3743 91.6446 101.104 85.0933C99.4187 85.2533 97.712 85.3333 96 85.3333C66.544 85.3333 42.6667 61.456 42.6667 32C42.6667 30.288 42.7467 28.5867 42.9067 26.896C36.3554 30.6257 30.9084 36.0234 27.1192 42.5404C23.33 49.0574 21.3338 56.4615 21.3333 64ZM96.8747 12.2187L101.333 13.3333V18.6667L96.8747 19.7813C94.9995 20.2503 93.2869 21.22 91.9201 22.5868C90.5533 23.9536 89.5836 25.6661 89.1147 27.5413L88 32H82.6667L81.552 27.5413C81.083 25.6661 80.1133 23.9536 78.7465 22.5868C77.3797 21.22 75.6672 20.2503 73.792 19.7813L69.3333 18.6667V13.3333L73.792 12.2187C75.6662 11.7488 77.3776 10.7788 78.7434 9.41201C80.1092 8.04527 81.0781 6.3332 81.5467 4.45867L82.6667 0H88L89.1147 4.45867C89.5836 6.33386 90.5533 8.04638 91.9201 9.41318C93.2869 10.78 94.9995 11.7497 96.8747 12.2187ZM123.541 38.8853L128 40V45.3333L123.541 46.448C121.666 46.917 119.954 47.8867 118.587 49.2535C117.22 50.6203 116.25 52.3328 115.781 54.208L114.667 58.6667H109.333L108.219 54.208C107.75 52.3328 106.78 50.6203 105.413 49.2535C104.046 47.8867 102.334 46.917 100.459 46.448L96 45.3333V40L100.459 38.8853C102.334 38.4164 104.046 37.4466 105.413 36.0798C106.78 34.713 107.75 33.0005 108.219 31.1253L109.333 26.6667H114.667L115.781 31.1253C116.25 33.0005 117.22 34.713 118.587 36.0798C119.954 37.4466 121.666 38.4164 123.541 38.8853Z" fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_143_305">
                                    <rect width="128" height="128" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="msg__text">
                        На данный момент здесь пусто.
                    </div>
                </div>

                <div v-else class="section__content">

                    <a :href="item.url" class="item" v-for="(item, index) in this.datas" :key="index">
                        <img :src="`../img/${item.img}`" class="item__img">
                        <!-- <img :src="require(`D:/ospanel/domains/localhost/img/${item.img}`)" class="item__img"> -->

                        <div class="item__info">
                            <div class="item__name">{{item.name}}</div>
                            <div v-if="item.desciption" class="item__desc">{{item.year}} • {{item.desciption}}</div>
                            <div v-if="!item.desciption" class="item__desc">{{item.year}}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import action from '../services/main.js'

export default {
    name: "Music",
    data() {
        return {
            datas: null
        }
    },
    mounted() {
        action.adminGetMusic().then(res =>{
            if(res.data[0]) {
                this.datas = res.data
            }else {
                this.datas = null
            }
        })
    }
}
</script>

<style scoped>
.item__info {
    padding: 10px 15px;
}
.item__name {
    font-size: 24px;
}
.item__desc {
    margin-top: 5px;
    font-size: 16px;
}
.section {
    margin: 50px 0;
}
.section__name {
    font-size: 32px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}
.section__content {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
}

.msg_box {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.msg__icn {

}
.msg__text {
    font-size: 16px;
}

@media screen and (min-width: 768px) {
    .item {
        width: calc(50% - 10px)!important;
    }
}

@media screen and (min-width: 1024px) {
    .item {
        width: calc(33% - 10px)!important;
    }
}

.item {
    width: 100%;

    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: .2s;
}
.item:hover {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    transition: .2s;
}
.item__img {
    width: 100%;
    border-radius: 15px;
}
.item__stats {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
}
.stats__ {
    display: flex;
    align-items: center;
    gap: 5px;
}
</style>